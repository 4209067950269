<template>
  <div class="transactions-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="8" :md="8" :sm="10" :xs="24">
        <h2 class="list-title">
          <font-awesome-icon
            class="anticon list-icon"
            :icon="['fas', 'arrow-right-arrow-left']"
          />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="16" :md="16" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="[0, 8]"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
            textAlign: 'right',
          }"
          class="row-reverse"
        >
          <a-col :xs="24" :md="filtered ? 12 : 13" :lg="filtered ? 14 : 15">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col
            :xs="24"
            :md="filtered ? 12 : 11"
            :lg="filtered ? 10 : 9"
            :style="{ textAlign: 'right' }"
          >
            <a-button-group>
              <a-button type="primary" icon="plus" @click="openFilter">
                {{ labels.filters.filters }}
              </a-button>
              <a-button
                v-if="filtered"
                type="danger"
                icon="close"
                @click="handleResetFilter"
              />
            </a-button-group>
            <router-link
              v-if="canProcessTransaction"
              :to="{ name: 'admin.transactions.process' }"
            >
              <a-button type="default" ghost icon="plus" class="add-button">
                <span>{{ labels.add }}</span>
              </a-button>
            </router-link>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <a-tabs
          type="card"
          :defaultActiveKey="STATUS.ALL"
          :animated="true"
          @change="handleChangeTab"
          :activeKey="activeTab"
        >
          <a-tab-pane :key="STATUS.ALL" :tab="statuses[0].label">
            <standard-table
              v-if="STATUS.ALL === activeTab"
              ref="standardTableAll"
              :fetchService="fetchTransactions"
              :columns="columns"
              :locale="locale"
              :handleShowTotal="handleShowTotal"
              v-bind="!isMobile && { scroll: { x: true } }"
              :paginationType="isMobile ? 'arrows' : 'numbers'"
              :customRow="handleCustomRow"
            />
          </a-tab-pane>
          <a-tab-pane :key="STATUS.PENDING" :tab="statuses[1].label">
            <standard-table
              v-if="STATUS.PENDING === activeTab"
              ref="standardTablePending"
              :fetchService="fetchTransactions"
              :columns="columns"
              :locale="locale"
              :handleShowTotal="handleShowTotal"
              v-bind="!isMobile && { scroll: { x: true } }"
              :paginationType="isMobile ? 'arrows' : 'numbers'"
              :customRow="handleCustomRow"
              :defaultFilters="{ status: STATUS.PENDING }"
            />
          </a-tab-pane>
          <a-tab-pane :key="STATUS.SUCCESS" :tab="statuses[2].label">
            <standard-table
              v-if="STATUS.SUCCESS === activeTab"
              ref="standardTableSuccess"
              :fetchService="fetchTransactions"
              :columns="columns"
              :locale="locale"
              :handleShowTotal="handleShowTotal"
              v-bind="!isMobile && { scroll: { x: true } }"
              :paginationType="isMobile ? 'arrows' : 'numbers'"
              :customRow="handleCustomRow"
              :defaultFilters="{ status: STATUS.SUCCESS }"
            />
          </a-tab-pane>
          <a-tab-pane :key="STATUS.CANCELLED" :tab="statuses[3].label">
            <standard-table
              v-if="STATUS.CANCELLED === activeTab"
              ref="standardTableCancelled"
              :fetchService="fetchTransactions"
              :columns="columns"
              :locale="locale"
              :handleShowTotal="handleShowTotal"
              v-bind="!isMobile && { scroll: { x: true } }"
              :paginationType="isMobile ? 'arrows' : 'numbers'"
              :customRow="handleCustomRow"
              :defaultFilters="{ status: STATUS.CANCELLED }"
            />
          </a-tab-pane>
          <a-tab-pane :key="STATUS.REJECTED" :tab="statuses[4].label">
            <standard-table
              v-if="STATUS.REJECTED === activeTab"
              ref="standardTableRejected"
              :fetchService="fetchTransactions"
              :columns="columns"
              :locale="locale"
              :handleShowTotal="handleShowTotal"
              v-bind="!isMobile && { scroll: { x: true } }"
              :paginationType="isMobile ? 'arrows' : 'numbers'"
              :customRow="handleCustomRow"
              :defaultFilters="{ status: STATUS.REJECTED }"
            />
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>
    <FilterForm
      ref="filterForm"
      :labels="labels.filters"
      :visible="showFilter"
      :onClose="closeFilter"
      :onSearch="onFilter"
      :filters="filters"
    />
  </div>
</template>

<script>
import _ from "lodash";
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import accounting from "accounting";
import FilterForm from "./FilterForm.vue";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  roles,
} from "../../../utils/utils";
const STATUS = {
  ALL: "ALL",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  CANCELLED: "CANCELLED",
  REJECTED: "REJECTED",
};
const initialState = {
  dateRange: [],
  type: [],
  origin: [],
  user: [],
  status: [],
  reference: "",
  ticket: "",
};
const formInitialState = {
  reject_reason: "",
};
export default {
  components: { StandardTable, FilterForm },
  name: "TransactionList",
  metaInfo: {
    title: adminPageTitle("Transacciones"),
  },
  data() {
    return {
      labels: labels.transactions,
      search: "",
      showFilter: false,
      filters: _.cloneDeep(initialState),
      form: _.cloneDeep(formInitialState),
      activeRow: null,
      STATUS,
      activeTab: STATUS.ALL,
      statuses: [
        {
          label: labels.transactions.statuses.all,
          value: "ALL",
        },
        {
          label: labels.transactions.statuses.pending,
          value: "PENDING",
        },
        {
          label: labels.transactions.statuses.processed,
          value: "SUCCESS",
        },
        {
          label: labels.transactions.statuses.cancelled,
          value: "CANCELLED",
        },
        {
          label: labels.transactions.statuses.rejected,
          value: "REJECTED",
        },
      ],
    };
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  methods: {
    ...mapActions("transactions", [
      "fetchTransactions",
      "cancelTransaction",
      "rejectTransaction",
    ]),
    getTableRefs() {
      switch (this.activeTab) {
        case STATUS.ALL:
          return this.$refs.standardTableAll;
        case STATUS.PENDING:
          return this.$refs.standardTablePending;
        case STATUS.SUCCESS:
          return this.$refs.standardTableSuccess;
        case STATUS.CANCELLED:
          return this.$refs.standardTableCancelled;
        case STATUS.REJECTED:
          return this.$refs.standardTableRejected;
        default:
          return this.$refs.standardTableAll;
      }
    },
    handleReloadTable() {
      let filtersTable = this.getTableRefs()?.filters;
      let result = { ...filtersTable };
      this.getTableRefs()?.handleFilters(result);
    },
    handleSearch(search) {
      this.$nextTick(() => {
        this.getTableRefs().handleSearch(search);
      });
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    confirmCancel(id) {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.cancelTransaction(id);
            this.$message.success(this.labels.cancelledSuccess);
            this.handleReloadTable();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.cancelledError
            );
          }
        },
      });
    },
    openFilter() {
      this.showFilter = true;
    },
    closeFilter() {
      this.showFilter = false;
    },
    onFilter() {
      let filtersTable = this.getTableRefs()?.filters;
      let result = { ...filtersTable, ...this.filters };
      Object.keys(result).forEach((key) => {
        switch (key) {
          case "status":
            if (!_.isEmpty(result[key]))
              result[key] = result[key]?.toUpperCase();
            break;
          case "type":
            if (!_.isEmpty(result[key])) result[key] = result[key].id;
            break;
          case "origin":
            if (!_.isEmpty(result[key])) result[key] = result[key].id;
            break;
          case "user":
            if (!_.isEmpty(result[key])) result[key] = result[key].id;
            break;
          case "dateRange":
            if (!_.isEmpty(result[key]))
              result[key] = result[key].map(
                (item) => item && item.format(this.serverDateFormat)
              );
            break;
        }
      });
      this.getTableRefs()?.handleFilters(result);
      this.closeFilter();
    },
    handleResetFilter() {
      this.filters = _.cloneDeep(initialState);
      this.onFilter();
      this.closeFilter();
    },
    statusColor(status = "success") {
      switch (status) {
        case "success":
        case "paid":
          return "#28a745";
        case "pending":
          return "#ffc107";
        case "cancelled":
        case "rejected":
          return "#f50";
        case "processed":
          return "#108ee9";
        default:
          return "";
      }
    },
    statusText(status = this.labels.table.columns.status.success) {
      return this.labels.table.columns?.status[status] ?? " - ";
    },
    confirmReject(record) {
      this.$confirm({
        title: this.labels.confirmReject.title,
        content: (h) =>
          h(
            "div",
            {
              class: "confirm-reject-content",
            },
            [
              h("p", this.labels.confirmReject.subtitle),
              h(
                "a-form-item",
                {
                  props: {
                    label: this.labels.form.rejectReason.label,
                    labelCol: { span: 24 },
                  },
                },
                [
                  h("a-textarea", {
                    props: {
                      placeholder: this.labels.form.rejectReason.placeholder,
                      value: this.form.reject_reason,
                      allowClear: true,
                      autoSize: { minRows: 3, maxRows: 5 },
                    },
                    on: {
                      input: (event) => {
                        this.form.reject_reason = event.target.value;
                      },
                    },
                  }),
                ]
              ),
            ]
          ),
        okText: this.labels.confirmReject.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirmReject.cancelButtonText,
        onOk: async () => {
          try {
            await this.rejectTransaction({
              id: record.id,
              ...(this.form.reject_reason && {
                reject_reason: this.form.reject_reason,
              }),
            });
            this.$message.success(this.labels.rejectedSuccess);
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.rejectedError
            );
          } finally {
            this.form = _.cloneDeep(formInitialState);
            this.handleReloadTable();
          }
        },
      });
    },
    handleCustomRow(_, index) {
      return {
        on: {
          click: () => {
            this.activeRow = this.activeRow === index ? null : index;
          },
        },
      };
    },
    handleShowMoreInfo(record, event) {
      event.stopPropagation();
      const linkToTicket = record?.ticket?.id
        ? this.$router.resolve({
            name: "admin.orders.view",
            params: { id: record?.ticket?.id },
          }).href
        : null;
      if (record?.id) {
        this.$info({
          title: this.labels.moreInfo.title.replace(
            "{code}",
            record?.code || "-"
          ),
          content: (
            <div>
              <p>
                <strong>{this.labels.moreInfo.date}:</strong>{" "}
                {this.$moment(record?.date).format(
                  DATE_FORMAT.MOMENT_DATE_TIME
                )}
              </p>
              <p>
                <strong>{this.labels.moreInfo.code}:</strong>{" "}
                {record?.code || "-"}
              </p>
              <p>
                <strong>{this.labels.moreInfo.reference}:</strong>{" "}
                {linkToTicket ? (
                  <a href={linkToTicket} target="_blank">
                    {record?.ticket?.number || record?.reference || "-"}
                  </a>
                ) : (
                  `${record?.ticket?.number || record?.reference || "-"}`
                )}
              </p>
              <p>
                <strong>{this.labels.moreInfo.user}:</strong>{" "}
                {record?.user?.email || "-"}
              </p>
              <p>
                <strong>{this.labels.moreInfo.type}:</strong>{" "}
                {record?.type?.name || "-"}
              </p>
              <p>
                <strong>{this.labels.moreInfo.origin}:</strong>{" "}
                {record?.origin?.name || "-"}
              </p>
              <p>
                <strong>{this.labels.moreInfo.amount}:</strong>{" "}
                {accounting.formatNumber(record?.amount, 2, ",", ".")}
              </p>
              <p>
                <strong>{this.labels.moreInfo.status}:</strong>{" "}
                {this.statusText(record?.status?.toLowerCase())}
              </p>
            </div>
          ),
        });
      }
    },
    handleChangeTab(key) {
      this.activeTab = key;
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text).format(DATE_FORMAT.MOMENT_DATE_TIME);
          },
        },
        {
          title: this.labels.table.columns.code,
          dataIndex: "code",
          key: "code",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${text}`;
          },
        },
        {
          title: this.labels.table.columns.reference,
          dataIndex: "ticket-reference",
          key: "ticket-reference",
          align: "center",
          hidden: this.isMobile,
          customRender: (_, record) => {
            return record?.ticket?.id ? (
              <RouterLink
                to={{
                  name: "admin.orders.view",
                  params: { id: record?.ticket?.id },
                }}
              >{`${
                record?.ticket?.number || record?.reference || "-"
              }`}</RouterLink>
            ) : (
              `${record?.ticket?.number || record?.reference || "-"}`
            );
          },
        },
        {
          title: this.labels.table.columns.user,
          dataIndex: "user",
          key: "user",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${text?.email || "-"}`;
          },
        },
        {
          title: this.labels.table.columns.type,
          dataIndex: "type",
          key: "type",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${text?.name || "-"}`;
          },
        },
        {
          title: this.labels.table.columns.origin,
          dataIndex: "origin",
          key: "origin",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return `${text?.name || "-"}`;
          },
        },
        {
          title: this.labels.table.columns.amount,
          dataIndex: "amount",
          key: "amount",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text = 0) => {
            return accounting.formatNumber(text, 2, ",", ".");
          },
        },
        {
          title: this.labels.table.columns.status.title,
          dataIndex: "status",
          key: "status",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return (
              <a-tag color={this.statusColor(text?.toLowerCase())}>
                {text
                  ? this.labels.table.columns.status[text?.toLowerCase()]
                  : "-"}
              </a-tag>
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          hidden: this.isMobile,
          customRender: (_, record) => {
            const isPending = record.status === "PENDING";
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.transactions.view",
                    params: { id: record.id },
                  }}
                  title={this.labels.table.columns.action.view}
                >
                  <a-button type="link" icon="eye" class="action-button-view" />
                </router-link>
                {!record.cancelled && this.canCancelTransaction && (
                  <a-button
                    type="link"
                    icon="close-circle"
                    class="action-button-cancel"
                    onClick={() =>
                      isPending
                        ? this.confirmReject(record)
                        : this.confirmCancel(record.id)
                    }
                    title={
                      isPending
                        ? this.labels.table.columns.action.reject
                        : this.labels.table.columns.action.delete
                    }
                  />
                )}
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.transactions,
          dataIndex: "transactions",
          key: "transactions",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_, record, index) => {
            // Card item design
            const color = this.statusColor(record.status?.toLowerCase());
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div
                    class="list-item-head-header"
                    style={{ borderColor: color }}
                  >
                    <span>
                      {this.$options.filters.ucwords(
                        this.$moment(record.date).format(
                          DATE_FORMAT.MOMENT_DAY_MONTH
                        )
                      )}
                    </span>
                    <small>
                      {this.$moment(record.date)
                        .format(DATE_FORMAT.MOMENT_TIME)
                        .toUpperCase()}
                    </small>
                  </div>
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-number">
                      <span>{record.code}</span>
                    </div>
                    <div class="list-item-head-content-type">
                      <span>{record.type?.name}</span>
                    </div>
                    <div class="list-item-head-content-user">
                      <span>{record.user?.email}</span>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <div class="list-item-head-footer-amount">
                      <span>
                        {accounting.formatNumber(record.amount, 2, ",", ".")}
                      </span>
                    </div>
                    <div class="list-item-head-footer-status">
                      <a-tag color={color}>
                        {record.status
                          ? this.labels.table.columns.status[
                              record.status?.toLowerCase()
                            ]
                          : "-"}
                      </a-tag>
                    </div>
                  </div>
                </div>
                {this.activeRow === index && (
                  <div class="list-item-actions">
                    <a-button-group>
                      <a-button
                        type="primary"
                        class="more-info-button"
                        title={this.labels.table.columns.action.moreInfo}
                        ghost
                        onClick={(event) =>
                          this.handleShowMoreInfo(record, event)
                        }
                      >
                        {this.labels.table.columns.action.moreInfo}
                      </a-button>
                      <router-link
                        class="view-button ant-btn ant-btn-primary ant-btn-block"
                        onClick={(event) => event.stopPropagation()}
                        to={{
                          name: "admin.transactions.view",
                          params: { id: record.id },
                        }}
                        title={this.labels.table.columns.action.view}
                      >
                        {this.labels.table.columns.action.view}
                      </router-link>
                      {!record.cancelled && this.canCancelTransaction && (
                        <a-button
                          type="danger"
                          class="cancel-button"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (record.status === "PENDING")
                              this.confirmReject(record);
                            else this.confirmCancel(record.id);
                          }}
                        >
                          {record.status === "PENDING"
                            ? this.labels.table.columns.action.reject
                            : this.labels.table.columns.action.cancel}
                        </a-button>
                      )}
                    </a-button-group>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((col) => !col.hidden);
    },
    filtered() {
      return !_.isEqual(this.filters, initialState);
    },
    serverDateFormat() {
      return DATE_FORMAT.SERVER_DATE;
    },
    canProcessTransaction() {
      return hasRoles(this.getLoggedUser?.accessRoles || [], [
        roles.admin.ROLE_TRANSACTIONS_PROCESS,
      ]);
    },
    canCancelTransaction() {
      return hasRoles(this.getLoggedUser?.accessRoles || [], [
        roles.admin.ROLE_TRANSACTIONS_CANCEL,
      ]);
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.transactions-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
    margin-left: 10px;
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-view {
      color: color(primary);
    }
    .action-button-cancel {
      color: color(danger);
    }
  }
  @media screen and (max-width: $screen-mobile) {
    .row-reverse {
      flex-direction: column-reverse;
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        max-width: 22%;
        border-right: 4px solid color(border);
        .success {
          color: color(success);
        }
        .warning {
          color: color(warning);
        }
        .danger {
          color: color(danger);
        }
        span {
          font-size: 14px;
          font-weight: 600;
        }
        small {
          font-size: 12px;
          font-weight: 400;
          padding-right: 4px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 53%;
        overflow: hidden;
        &-number {
          span {
            font-size: 14px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-type {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
        &-user {
          span {
            font-size: 12px;
            font-weight: 400;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        &-amount {
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
        &-status {
          span {
            font-size: 12px;
            font-weight: 400;
          }
          .ant-tag {
            padding: 0;
            margin: 0;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
    }
    &-actions {
      width: 100%;
      margin-top: 10px;
      .ant-btn-group {
        width: 100%;
        .more-info-button,
        .view-button,
        .cancel-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
